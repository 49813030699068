import API from 'api';

export const fetchProductsByCategory = async data => {
  try {
    const response = await API.post(
      'v1/shopItems/CategoryCodeOrProductName',
      data,
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchProductDetailById = async data => {
  try {
    const response = await API.post('v1/shopItem/getDetailById', data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchProductReviews = async data => {
  try {
    const response = await API.post('v1/shopItemReview/all', data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchMainBestItems = async data => {
  try {
    const response = await API.post('v1/shopBestItem/random', data);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};



export const fetchLatestItems = async data => {
  try {
    const response = await API.post(
      'v1/shopItems/latest',
      data,
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchEventItemById = async ({ id, page }) => {
  try {
    const response = await API.post('v1/shopEventItem/allByEventId', {
      eventId: id,
      page: page,
      size: 20,
      viewDirection: 'DESC',
      viewStandard: 'margin',
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
