import { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Rating,
  Grid,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';

import SortIcon from '@mui/icons-material/Sort';

import Divider from 'components/Divider';
import Modal from 'components/Modal';
import TextReview from 'pages/product/Review/TextReview';
import PhotoReview from 'pages/product/Review/PhotoReview';
import ScoreReview from 'pages/product/Review/ScoreReview';
import ImageViewer from 'pages/product/Review/ImageViewer';

import { getProductReviews } from 'store/mall';
import { setIsOpenModal } from 'store/app';

export default function Review() {
  const [bestButtonEl, setBestButtonEl] = useState(null);
  const productId = useSelector(state => state?.mall.productDetail.itemId);
  const reviews = useSelector(state => state.mall?.productReviews);
  const productScoreInfo = useSelector(
    state => state.mall?.productDetail.scoreInfo,
  );
  const productReviewImages = useSelector(
    state => state.mall?.productDetail.reviewImages,
  );

  const isOpenReportModal = useSelector(state => state.app?.isOpenModal.report);
  const isOpenImageReviewModal = useSelector(
    state => state.app?.isOpenModal.imageReview,
  );

  const data = {
    condition: 'data',
    count: 10,
    direction: 'DESC',
    itemId: productId,
    page: 1,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductReviews(data));
  }, []);

  const handleCloseReportModal = () => {
    dispatch(
      setIsOpenModal({
        name: 'report',
        value: false,
      }),
    );
  };

  const handleOpenReportModal = () => {
    dispatch(
      setIsOpenModal({
        name: 'report',
        value: true,
      }),
    );
  };

  const handleCloseImageReviewModal = () => {
    dispatch(
      setIsOpenModal({
        name: 'imageReview',
        value: false,
      }),
    );
  };

  return (
    <>
      <Modal
        closeButton
        fullScreen
        open={isOpenReportModal}
        onClose={handleCloseReportModal}
      >
        <h1>Report Modal</h1>
      </Modal>

      <Modal
        closeButton
        fullScreen
        open={isOpenImageReviewModal}
        onClose={handleCloseImageReviewModal}
      >
        <ImageViewer />
      </Modal>

      <Grid container py={2}>
        <Grid item xs>
          <Grid container flexDirection="column" alignItems="center">
            <Typography fontSize="42px" fontWeight={700}>
              {productScoreInfo.reviewScoreAvg?.toFixed(1) || 0}
            </Typography>
            <Rating
              precision={0.1}
              defaultValue={0}
              value={Number(productScoreInfo?.reviewScoreAvg?.toFixed(1))}
              readOnly
            />
          </Grid>
        </Grid>

        <Grid item xs sx={{ height: 1 }}>
          <Grid container p={2} gap={1} justifyContent="center">
            <ScoreReview
              scoreCounts={productScoreInfo?.scores}
              totalReviewCount={productScoreInfo?.totalReviewCount}
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider thickness="thick" />

      {/*<Box p={2}>*/}
      {/*  <Typography fontSize="16px" fontWeight={700}>*/}
      {/*    포토 후기*/}
      {/*  </Typography>*/}
      {/*</Box>*/}

      {/*<Divider />*/}

      {/*<Box p={2}>*/}
      {/*  <Grid container>*/}
      {/*    {productReviewImages.length ? (*/}
      {/*      <PhotoReview images={productReviewImages} />*/}
      {/*    ) : (*/}
      {/*      <Typography*/}
      {/*        sx={{ width: 1 }}*/}
      {/*        py={2}*/}
      {/*        fontSize="12px"*/}
      {/*        fontWeight={600}*/}
      {/*        align="center"*/}
      {/*      >*/}
      {/*        아직 작성된 포토후기가 없어요.*/}
      {/*      </Typography>*/}
      {/*    )}*/}
      {/*  </Grid>*/}
      {/*</Box>*/}

      {/*<Divider thickness="thick" />*/}

      <Box p={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography fontSize="16px" fontWeight={700}>
              전체후기 {productScoreInfo.totalReviewCount}건
            </Typography>
          </Grid>
          {/*
          <Grid item>
            <Button
              startIcon={<SortIcon />}
              onClick={e => setBestButtonEl(e.currentTarget)}
            >
              베스트
            </Button>
            <Menu
              anchorEl={bestButtonEl}
              open={Boolean(bestButtonEl)}
              onClose={() => setBestButtonEl(null)}
            >
              <MenuItem onClick={() => setBestButtonEl(null)}>베스트</MenuItem>
              <MenuItem onClick={() => setBestButtonEl(null)}>최신순</MenuItem>
              <MenuItem onClick={() => setBestButtonEl(null)}>별점순</MenuItem>
            </Menu>
          </Grid>
          */}
        </Grid>
      </Box>

      <Divider />

      {reviews.length ? (
        reviews.map(review => (
          <Fragment key={review.reviewId}>
            <TextReview review={review} onClick={handleOpenReportModal} />
            <Divider />
          </Fragment>
        ))
      ) : (
        <Box px={2} py={4}>
          <Typography fontSize="12px" fontWeight={600} align="center">
            아직 작성된 후기가 없어요.
          </Typography>
        </Box>
      )}
    </>
  );
}
