import axios from 'axios';

const API = axios.create({
    //baseURL: 'http://api.adsmall.co.kr/api',
    // baseURL: 'http://49.254.175.22/api',
    baseURL: 'http://api.adsmall.co.kr/api',
    //baseURL: 'http://192.82.66.43:8086/api',
});

API.interceptors.request.use(
  config => {
    // console.log(config)

    // Figure out how to use this codes
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const authInterceptor = {};

API.setAuthInterceptor = (token, signOut) => {
  authInterceptor.req = API.interceptors.request.use(config => {
    config.headers.authorization = token;

    return config;
  });

  authInterceptor.res = API.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        signOut();
          window.location.href = 'https://adsward.co.kr/logout';
      }

      return Promise.reject(error);
    },
  );
};

API.clearAuthInterceptor = () => {
  API.interceptors.request.eject(authInterceptor.req);
  API.interceptors.response.eject(authInterceptor.res);
};

export const getAPICanceler = () => axios.CancelToken.source();

export default API;
