import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTab: {
    homeTab: 0,
    bestSortTab: 0,
    savingSortTab: 0,
    categoryTab: 0,
    myPageTab: 0,
  },
  sortBy: {
    best: 'DAY',
    savingsPoint: 'margin',
    latest: 'update',
  },
  activeBottomNavTabName: 'point-shop',
  activeTab: {
    home: 0,
    homeTab:0,
    profile: 0,
    product: 0,
    drawer: 0,
    drawerCate: 0,
    secondDrawerCate: 0,
    thirdDrawerCate: 0,
    best: 0,
    savingsPoint: 0,
  },
  activeMenu: {
    drawerCate: "01",
    secondDrawerCate: "0101",
    thirdDrawerCate: "",
  },
  isOpenDrawer: {
    menu: false,
    search: false,
  },

  isOpenModal: {
    report: false,
    imageReview: false,
    terms: false,
  },

  isOpenBox: {
    order: false,
  },

  currentImageViewerUrl: '',
  scrollTop: true,
};

export const counterSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setScrollTop: (state, action) => {
      state.scrollTop = action.payload;
    },
    rememberPage: (state, action) => {
      state.prevPage = action.payload;
    },
    changeBottomTab: (state, action) => {
      state.activeBottomNavTabName = action.payload;
    },
    toggleDrawer: (state, action) => {
      state.isOpenDrawer[action.payload.name] = action.payload.isOpenDrawer;
    },
    changeActiveTab: (state, action) => {
      state.activeTab[action.payload.name] = action.payload.nextTabIdx;
    },
    changeActiveMenu: (state, action) => {
      state.activeMenu[action.payload.name] = action.payload.nextTabIdx;
    },
    setCurrentSortBy: (state, action) => {
      state.sortBy[action.payload.name] = action.payload.value;
    },
    setIsOpenModal: (state, action) => {
      state.isOpenModal[action.payload.name] = action.payload.value;
    },
    setIsOpenBox: (state, action) => {
      state.isOpenBox[action.payload.name] = action.payload.value;
    },
    setCurrentImageViewerUrl: (state, action) => {
      state.currentImageViewerUrl = action.payload;
    },
  },
});

export const {
  setScrollTop,
  rememberPage,
  changeBottomTab,
  toggleDrawer,
  changeActiveTab,
  changeActiveMenu,
  setCurrentSortBy,
  setIsOpenModal,
  setIsOpenBox,
  setCurrentImageViewerUrl,
} = counterSlice.actions;
export default counterSlice.reducer;
