import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { MdArrowForwardIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import { signOut } from 'store/auth';

import ChannelService from 'utils/ChannelService';

export default function MyPageBottom() {
  const dispatch = useDispatch();
  const user = useSelector(state => state?.auth.user);
  const redirectPage = url => {
    window.location.href = url;
    // redirect(url);
  };
  const navigate = useNavigate();

  useEffect(() => {
    activateChat();

    return () => shutdownChat();
  }, []);
  const activateChat = () => {
    // Boot Channel as an anonymous user
    // ChannelService.boot({
    //   pluginKey: '9eddb3b7-31ef-483b-a6b7-afe9512bac68',
    //   customLauncherSelector: '#openAsk',
    //   hideChannelButtonOnBoot: true,
    // });

    // Boot Channel as a registered user
    ChannelService.boot({
      pluginKey: '9eddb3b7-31ef-483b-a6b7-afe9512bac68',
      customLauncherSelector: '#openAsk',
      hideChannelButtonOnBoot: true,
      mobileMessengerMode: 'iframe',
      memberId: user?.id,
      profile: {
        name: user?.name, //fill with user name
        // mobileNumber: user?.phone, //fill with user phone number
        // CUSTOM_VALUE_1: 'VALUE_1',
        // CUSTOM_VALUE_2: 'VALUE_2',
      },
    });
  };
  const shutdownChat = () => {
    // Shutdown Channel
    ChannelService.shutdown();
  };

  return (
    <MyPagLinkList>
      <ul>
        {/* <li
          onClick={() => {
            redirectPage('https://adsward.co.kr/mypage/pushSetting');
          }}
        >
          <span>광고알림설정</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li>
        <li
          onClick={() => {
            redirectPage('https://adsward.co.kr/mypage/viewRewardAdvertisement');
          }}
        >
          <span>내가 본 광고</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li> */}
{/*        <li
          onClick={() => {
            redirectPage('https://adsward.co.kr/mypage/saveHistoryList');
          }}
        >
          <span>포인트적립내역</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li>*/}

         {/* <li
          onClick={() => {
            navigate('/pointhistory');
          }}
        >
          <span>쇼핑적립내역</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li> */}
{/*        <li
          onClick={() => {
            redirectPage('https://adsward.co.kr/mypage/paySwitchHistoryList');
          }}
        >
          <span>포인트사용내역</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li> */}

        {/* <li
          onClick={() => {
            redirectPage('https://adsward.co.kr/mypage/adwardReport');
          }}
        >
          <span>애즈워드 현황</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li> */}
        {/* <li
          onClick={() => {
            redirectPage('https://adsward.co.kr/mypage/adwardRanking');
          }}
        >
          <span>랭킹 보기</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li> */}
        <li
          onClick={() => {
            redirectPage('https://adsward.co.kr/board/noticeList');
          }}
        >
          <span>공지사항</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li>
        <li
          onClick={() => {
            navigate('/FAQ');
          }}
        >
          <span>FAQ</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li>
        <li id="openAsk">
          <span>문의하기</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li>
        <li
            onClick={() => {
              dispatch(signOut());
              window.location.href = 'https://adsward.co.kr/logout';
            }}
        >
          <span>로그아웃</span>
          <span>
            <MdArrowForwardIos />
          </span>
        </li>
      </ul>
    </MyPagLinkList>
  );
}

const MyPagLinkList = styled.div`
  ${({ theme }) => {
    return css`
      > ul {
        padding-left: 0px;
        margin: 0px;
        li {
          border: 1px solid #f1f1f5;
          background-color: #fff;
          padding: 15px;
          padding-left: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    `;
  }}
`;
