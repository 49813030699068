import styled, { css } from 'styled-components';
import { ReactComponent as PointIcon } from 'assets/icons/point.svg';

import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { SvgIcon } from '@mui/material';
import { parseStringToPrice } from 'utils';
import { getUserInfo } from 'store/auth';
import { useEffect } from 'react';

export default function MyPageSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const point = useSelector(state => state.auth.userPoint);
  const redirectPage = url => {
    window.location.href = url;
  };

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  return (
    <Box>
      <MyPageInfoName>
        <span>
          <b>{user.name}</b>님
        </span>
        <MyPageInfoButtonWrap>
          <MyPagInfoButton
            onClick={() => redirectPage('https://adsward.co.kr/mypage/userEdit')}
          >
            회원정보 수정
          </MyPagInfoButton>
          <MyPagInfoButton
            onClick={() =>
              redirectPage('https://adsward.co.kr/board/noticeList')
            }
          >
            공지
          </MyPagInfoButton>
        </MyPageInfoButtonWrap>
      </MyPageInfoName>
      <MyPagInfoBenefit>
        <div onClick={() => navigate('/pointhistory')}>
          <div>
            <SvgIcon component={PointIcon} />
            <span className="benefit_size-small">현재 포인트</span>
          </div>
          <div>
            <span style={{ color: '#FFC94A' }}>
              {parseStringToPrice(point)} P
            </span>
          </div>
        </div>
      </MyPagInfoBenefit>
    </Box>
  );
}

const MyPageInfoName = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      margin-bottom: 30px;
      > span {
        font-size: 14px;
        font-weight: bold;
        color: #999999;
        > b {
          color: #191919;
        }
      }
    `;
  }}
`;

const MyPageInfoButtonWrap = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      gap: 10px;
    `;
  }}
`;

const MyPagInfoButton = styled.button`
  background: inherit;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  padding: 5px 15px;
  overflow: visible;
  cursor: pointer;
  color: #767676;
  font-size: 12px;
  font-weight: bold;
  ${({ clicked }) => {
    return clicked
      ? `
                    border : 1px solid red;
                    color : red
                    `
      : null;
  }}
`;

const MyPagInfoBenefit = styled.div`
  ${({ theme }) => {
    return css`
      padding: 0 15px;
      margin-bottom: 10px;
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
        border-radius: 10px;
        > div {
          padding: 15px 20px;
          display: flex;
          align-items: center;
          > span {
            color: #767676;
            font-weight: bold;
            > b {
              color: #191919;
            }
            &.benefit_size-small {
              margin-left: 10px;
              font-size: 12px;
            }
          }
        }
      }
    `;
  }}
`;

const TabBar = styled.span`
  ${({ theme }) => {
    return css`
      position: absolute;
      bottom: 0;
      width: 100%;
      border: 1px solid red;
      ${({ tabPosition }) => {
        return `
                    left : ${tabPosition}px; 
                    transition : left .5s ease;
                    `;
      }}
    `;
  }}
`;

const StyledLink = styled(NavLink)`
  ${({ theme }) => {
    return css`
      text-decoration: none;
      text-align: center;
      border: none;
      padding: 15px;
      overflow: visible;
      color: #767676;
      position: relative;
      &.active {
        color: black;
        font-weight: bold;
      }
    `;
  }}
`;
