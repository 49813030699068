import Modal from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Typography, List } from '@mui/material';
import QueryString from 'qs';
import { Box } from '@mui/system';

import AgreeTermsDetail from './AgreeTermsDetail';
import { agreeTerms } from 'api/auth';
import { setIsOpenModal } from 'store/app';
import { getValidUser } from 'store/auth';
import { useEffect } from 'react';
import { encodeToken } from 'utils';
import { terms } from 'contants/terms';

export default function AgreeTerms({ open }) {
  const dispatch = useDispatch();
  const storage = sessionStorage;
  const location = useLocation().search;
  const isOpenTermsModal = useSelector(state => state.app.isOpenModal.terms);
  const params = QueryString.parse(location, {
    ignoreQueryPrefix: true,
  });

  let token = '';
  useEffect(() => {
    if (Object.keys(params).includes('ALC')) {
      token = encodeToken({ alc: params.ALC });
    } else {
      token = encodeToken({
        refreshToken: storage.getItem('REFRESH_AUTH_TOKEN'),
      });
    }
  }, [isOpenTermsModal]);

  const redirect = () => window.location.replace('https://adsward.co.kr/main');
  const sendAgreement = async () => {
    await agreeTerms();
    dispatch(getValidUser(token));
  };

  return (
    <Modal open={open} maxWidth="xs" fullWidth>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'relative',
          top: 0,
          left: 0,
          marginBottom: '2px',
          marginTop: '3px',
          padding: '0 15px',
        }}
      >
        <Typography
          fontSize="0.8rem"
          // align="center"
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            py: 2,
            bgcolor: '#fff',
            lineHeight: '1.6',
          }}
        >
          기존 회원께서는 변경된 '이용약관'과 '개인정보보호정책'을 보시고 계속
          이용을 위해서 동의해 주시기 바랍니다. <br />
          &lt;쇼핑수익&gt;등 다양한 '소비자 수익' 콘텐츠를 제공하기 위해
          변경하였습니다.
          <br />
          변경된 사항에 대해 '동의하지 않습니다'를 선택할 수 있으나 신규 적립 등
          이용에 제한이 있습니다.
        </Typography>
        <Box
          mb={3}
          sx={{
            border: '1px solid',
            borderColor: 'grey.300',
            borderRadius: 1.5,
          }}
        >
          <List component="ul">
            {terms.map((item, index) => (
              <AgreeTermsDetail
                key={index}
                termsDetail={terms[index].detail}
                termsTitle={terms[index].title}
              />
            ))}
          </List>
        </Box>
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
            paddingTop: 1,
          }}
        >
          <Button
            fullWidth
            variant="contained"
            sx={{ mb: 1 }}
            onClick={() => {
              sendAgreement();
              dispatch(
                setIsOpenModal({
                  name: 'terms',
                  value: false,
                }),
              );
            }}
          >
            약관에 모두 동의합니다.
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{ mb: 1 }}
            onClick={redirect}
          >
            동의하지 않습니다.
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
