import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {CardMedia, IconButton, Box, LinearProgress, Alert} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as Cart } from 'assets/icons/cart_header.svg';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import OrderSection from 'pages/coupon/OrderSection';

import Container from 'components/atom/Container';
import ScrollTop from 'components/ScrollTop';

import TextSet from 'components/coupon/TextSet';

import OrderButton from 'components/product/OrderButton';

import DetailsSection from 'pages/coupon/DetailsSection';
import {  getCouponData } from 'store/coupon';
import {  setProductOptionDetail } from 'store/mall';
import { changeActiveTab, setIsOpenBox } from 'store/app';

import Footer from 'components/layout/Footer';
import {Alarm, Search} from '@mui/icons-material';

export default function Coupon() {
  const params = useParams();

  const isOpenBox = useSelector(state => state?.app.isOpenBox.order);
  const couponDetail = useSelector(state => state?.coupon.coupon);
  const status = useSelector(state => state?.coupon.status);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setIsOpenBox({ name: 'order', value: false }));
  }, []);

  useEffect(() => {
    (() => {
      dispatch(getCouponData({ itemId: params.id }));
    })();
  }, []);

  useEffect(() => {
    if (status === 'loading') {
      return <LinearProgress />;
    }
  }, [status]);
    useEffect(() => {
        addNoOptionProductInfo();
    }, [couponDetail]);


    function addNoOptionProductInfo() {
        dispatch(
            setProductOptionDetail([
                {
                    type: 'none',
                    qty: 1,
                },
            ]),
        );
    }

  const copyUrl = () => {
    const currentUrl = window.location.href;

    var input = document.createElement('input');
    document.body.appendChild(input);
    input.value = currentUrl;
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    alert('주소가 복사되었습니다');

  };

  return (
    <>
      <Container
        sx={{
          position: 'fixed',
          '&::before': {
            content: `""`,
            opacity: '0.27',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            display: 'block',
            height: '80px',
            background: 'linear-gradient(#000 0%, rgba(0, 0, 0, 0) 100%)',
          },
        }}
      >
        {status === 'succeeded' && (
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', pr: 1.2 }}
          >
            <Box>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{ p: 0, pl: '12px', py: '12px', color: '#fff' }}
                aria-label="go back"
                size="large"
              >
                <ArrowBackIosRoundedIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  navigate('/coupon');
                  dispatch(changeActiveTab({ name: 'home', nextTabIdx: 0 }));
                }}
                sx={{ p: 0, py: '12px', color: '#fff' }}
                aria-label="go home"
                size="large"
              >
                <HomeOutlinedIcon />
              </IconButton>
            </Box>
            <Box>
              <IconButton onClick={() => navigate('/cart')}>
                <Cart />
              </IconButton>
              <IconButton onClick={() => navigate('/search')}>
                <Search sx={{ color: '#fff' }} />
              </IconButton>
            </Box>
          </Box>
        )}
      </Container>
      {status === 'loading' && <LinearProgress />}
      <CardMedia
        // ref={scrollTopAnchorRef}
        component="img"
        image={ status === 'succeeded' ? couponDetail.imageUrl : ''}
        sx={{ height: '400px', p: 0 }}
      />

      <Box p={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {status === 'succeeded' && (
          <>
            <TextSet product={couponDetail} />
            <IconButton aria-label="share" onClick={copyUrl}>
              <ShareOutlinedIcon />
            </IconButton>
          </>
        )}
      </Box>

      {status === 'succeeded' && (
        <>
            <DetailsSection />
          <StickyBox open={isOpenBox}>
            {!isOpenBox && (
              <OrderButton
                variant="contained"
                name="order"
                onClick={e => {
                    if(couponDetail.error === couponDetail.itemId)
                    {
                        alert("지난 48시간 동안 이 쿠폰을 이미 주문하셨습니다.");
                    }
                    else{
                        dispatch(
                            setIsOpenBox({
                                name: e.target.name,
                                value: true,
                            }),
                        );
                    }

                }}
              >
                주문하기
              </OrderButton>
            )}

            {isOpenBox && <OrderSection />}
          </StickyBox>
        </>
      )}

      <ScrollTop />
      <Footer />
    </>
  );
}

const StickyBox = ({ children, open }) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 1200,
        backgroundColor: '#ffffff',
        bottom: 70,
        p: 1.5,
        borderRadius: open && '30px 30px 0 0',
        boxShadow: '0px -3px 6px rgb(0 0 0 / 10%)',
      }}
    >
      {children}
    </Box>
  );
};

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          justifyContent: 'space-evenly',
        },
        indicator: {
          backgroundColor: '#191919',
          height: '3px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          minWidth: 0,
          '&.Mui-selected': {
            font: 'normal normal bold 14px/20px Noto Sans CJK KR',
            color: '#191919',
          },
        },
      },
    },
  },
});
