import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Button,
  Grid,
  IconButton,
  Pagination,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { parseStringToPrice, parseDate } from 'utils';

import Divider from 'components/Divider';
import OrderDetailInfo from './order/OrderDetailInfo';
import { fetchPaidOrderList } from 'api/order';
import { getPaidOrderList } from 'store/order';
import DeliveryStatus from './DeliveryStatus';

// import Modal from 'components/Modal';

export default function Orders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paidOrderTotalCount = useSelector(
    state => state?.order.paidOrderList.orderInfoCount,
  );

  const [paidOrderData, setPaidOrderData] = useState({});
  const [paidOrderList, setPaidOrderList] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelivery, setOpenDelivery] = useState(false);
  const [detailInfo, setDetailInfo] = useState();

  useEffect(async () => {
    const res = await fetchPaidOrderList(1);
    dispatch(getPaidOrderList(1));
    setPaidOrderList(res?.data.orderInfos);
    setPaidOrderData(res?.data);

    return async () => {
      await fetchPaidOrderList(1);
    };
  }, []);

  const countTotalPage = () => {
    if (typeof paidOrderTotalCount !== 'number') return;

    return Math.ceil(paidOrderTotalCount / 10);
  };

  const handleChangePage = async page => {
    const res = await fetchPaidOrderList(page);

    dispatch(getPaidOrderList(page));

    setPaidOrderList(res.data.orderInfos);
  };

  const checkAllReviewWritten = item => {
    const notWritten = item?.filter(data => data.reviewWrite === false);

    if (notWritten?.length !== 0) return false;
    return true;
  };

  return (
    <Box p={2}>
      <OrderDetailInfo
        open={open}
        setOpen={setOpen}
        info={detailInfo}
        openDelivery={openDelivery}
        setOpenDelivery={setOpenDelivery}
      />
      <DeliveryStatus
        open={openDelivery}
        setOpen={setOpenDelivery}
        info={detailInfo}
      />
      {(paidOrderList?.length === 0 || !paidOrderList) && (
        <Typography fontSize="0.9rem" sx={{ p: 4, textAlign: 'center' }}>
          내역이 없습니다
        </Typography>
      )}
      {paidOrderList?.map(data => {
        return (
          <React.Fragment key={data.orderInfoId}>
            <Card sx={{ p: 2, mb: 2 }}>
              <Box
                mb={1}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography fontSize="0.9rem" color="grey.500">
                  {parseDate(data.orderDate)} 주문
                </Typography>
                <Typography
                  fontSize="0.9rem"
                  color="grey.600"
                  onClick={() => {
                    setOpen(true);
                    setDetailInfo(data);
                  }}
                >
                  주문상세 &gt;
                </Typography>
              </Box>
              <Grid container mb={1.2} gap={1}>
                <Grid
                  item
                  xs={3.5}
                  onClick={e => {
                    navigate('/product/' + data.orderItems[0].shopItemId);
                  }}
                >
                  <img
                    src={data.orderItems[0].thumbnail}
                    alt={data.orderItems[0].itemName}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={7.5}>
                  <Typography>
                    {data.orderItems[0].itemName}{' '}
                    <Typography
                      variant="span"
                      fontWeight="bold"
                      color="grey.600"
                    >
                      {data.orderItems?.length > 1 &&
                        ` 외 ${data.orderItems?.length - 1}건`}
                    </Typography>
                  </Typography>
                  <Typography>
                    <Typography variant="span" fontWeight="bold">
                      {' '}
                      {parseStringToPrice(data.orderItems[0]?.orderItemPrice)}
                    </Typography>
                    원
                  </Typography>
                  <Typography fontSize="0.8rem" fontWeight="bold" color="#FF203B">
                    수익금 {parseStringToPrice(data.orderItems[0]?.orderItemGetPoint)}P
                  </Typography>
                </Grid>
              </Grid>

              {/* {data.orderItems?.map(item => (
              <React.Fragment key={item.shopItemId}>
                <Grid container mb={1.2} gap={1}>
                  <Grid
                    item
                    xs={3.5}
                    onClick={e => {
                      navigate('/product/' + item.shopItemId);
                    }}
                  >
                    <img
                      src={item.thumbnail}
                      alt={item.itemName}
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={7.5}>
                    <Typography>{item.itemName}</Typography>
                    <Typography>
                      <Typography variant="span" fontWeight="bold">
                        {' '}
                        {parseStringToPrice(item.orderItemPrice)}
                      </Typography>
                      원
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ borderColor: 'grey.100' }} />
              </React.Fragment>
            ))} */}

              <Divider sx={{ borderColor: 'grey.100' }} />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 1,
                  mt: 1,
                }}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    setOpenDelivery(true);
                    setDetailInfo(data);
                  }}
                >
                  배송현황
                </Button>

                {data.purchaseConfirmation ? (
                  <>
                    {checkAllReviewWritten(data.orderItems) ? (
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => {
                          if (data.orderItems?.length === 1) {
                            navigate(
                                `/product/${data.orderItems[0].shopItemId}`,
                            );
                          }
                        }}
                      >
                        제품 상세 보기
                      </Button>
                    ) :
                    (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                              if (data.orderItems?.length === 1) {
                                navigate(
                                    `/orderhistory/${data.orderInfoId}/review/${data.orderItems[0].orderItemId}`,
                                );
                              }
                            }}
                        >
                          후기 작성
                        </Button>
                    )}
                  </>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      navigate(`/orderhistory/${data.orderInfoId}`);
                    }}
                  >
                    구매확정
                  </Button>
                )}
              </Box>
            </Card>
          </React.Fragment>
        );
      })}
      {paidOrderList && paidOrderList?.length !== 0 && (
        <Stack
          spacing={2}
          sx={{ py: 3, ul: { display: 'flex', justifyContent: 'center' } }}
        >
          <Pagination
            count={countTotalPage()}
            shape="rounded"
            onChange={(e, currentPage) => handleChangePage(currentPage)}
          />
        </Stack>
      )}
    </Box>
  );
}
