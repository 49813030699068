import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid, SvgIcon, Typography, IconButton } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { ReactComponent as AdswordLogo } from 'assets/icons/adsward_r.svg';
import { ReactComponent as PointIcon } from 'assets/icons/point.svg';

import { Container } from 'components/atom/index';
import TabBar from 'components/layout/TabBar';
import TabPanel from 'components/layout/TabPanel';
import Divider from 'components/Divider';
import { getUserInfo, signOut } from 'store/auth';
import { Link, useNavigate } from 'react-router-dom';
import { changeActiveMenu, changeActiveTab, toggleDrawer } from 'store/app';

import {
  getProductsByCategory,
  getCategoriesByParentCode,
  getThirdLevelCategoriesByParentCode,
  setTabIndex,
  setCategoryProductList,
} from 'store/categorySort';

import { terms } from 'contants/terms';
import Modal from 'components/Modal2';
import Header from 'components/layout/Header';
import { setCurrentCategory } from 'store/mall';
import { parseStringToPrice } from 'utils';

export default function Menu({ onClick }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const point = useSelector(state => state?.auth.userPoint);
  const activeMenu = useSelector(state => state?.app.activeMenu);
  const activeTab = useSelector(state => state?.app.activeTab);
  const isOpenDrawerMenu = useSelector(state => state?.app.isOpenDrawer.menu);
  const categories = useSelector(state => state?.mall.categories);
  const secondLevelCategoriesByParentCode = useSelector(
    state => state?.categorySort.categories?.second,
  );
  const thirdLevelCategoriesByParentCode = useSelector(
        state => state?.categorySort.categories?.third,
  );

  const newCategories = categories.filter(
    item => item.code !== 'all' && item.rank === 1,
  );

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    dispatch(getCategoriesByParentCode(activeMenu.drawerCate));
    dispatch(getThirdLevelCategoriesByParentCode(activeMenu.secondDrawerCate));
  }, []);

  const moveURLTo = url => {
    window.location.href = url;
  };

  const handleJumpTab = async (code, idx) => {
      dispatch(setCategoryProductList([]));
      dispatch(setCurrentCategory(code));
    dispatch(
      getProductsByCategory({
        categoryCode: code.slice(0, 6),
        page: 1,
        count: 20,
        viewStandard: 'margin',
        viewDirection: 'DESC',
      }),
    );
      dispatch(getCategoriesByParentCode(code.slice(0, 2)));
      dispatch(getThirdLevelCategoriesByParentCode(code.slice(0, 4)));

      dispatch(changeActiveMenu({ name: 'drawerCate', nextTabIdx: code.slice(0, 2) }));
      dispatch(changeActiveMenu({ name: 'secondDrawerCate', nextTabIdx: code.slice(0, 4) }));
      dispatch(changeActiveMenu({ name: 'thirdDrawerCate', nextTabIdx: code.slice(0, 6) }));

    navigate('/category');
  };
    const handleClickSecondCat = async (code, idx) => {
        dispatch(setCategoryProductList([]));
        dispatch(setTabIndex(idx));
        dispatch(setCurrentCategory(code));
        dispatch(getThirdLevelCategoriesByParentCode(code.slice(0, 4)));
        dispatch(changeActiveMenu({ name: 'secondDrawerCate', nextTabIdx: code.slice(0, 4) }));
        dispatch(changeActiveMenu({ name: 'thirdDrawerCate', nextTabIdx: "" }));
    };
  const handleToggleMenu = async e => {
    dispatch(
      toggleDrawer({
        name: 'menu',
        isOpenDrawer: !isOpenDrawerMenu,
      }),
    );
  };

  return (
    <Container>
      <Box p={2}>
        <Grid container justifyContent="space-between">
          <SvgIcon
            style={{ width: '25%' }}
            component={AdswordLogo}
            inheritViewBox
            fontSize="large"
          />
          <IconButton name="menu" edge="end" onClick={onClick}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Box
          sx={{
            p: 1,
            border: '1px solid #F1F1F5',
            borderRadius: '10px',
            boxShadow: '0 3px 6px #0000000F',
          }}
        >
          <Grid
            container
            p={1}
            justifyContent="space-between"
            onClick={() => {
              navigate('/pointhistory');
              handleToggleMenu();
            }}
          >
            <Grid>
              <Grid container alignItems="center">
                <SvgIcon component={PointIcon} />
                <Typography pl={1} fontSize="12px" color="#767676">
                  현재 포인트
                </Typography>
              </Grid>
            </Grid>

            <Grid>
              {parseStringToPrice(point)}{' '}
              <Typography component="span" style={{ color: '#999999' }}>
                P
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Divider color="#EBEBEB" thickness="thin" />
      {/*<ThemeProvider theme={theme}>*/}
      {/*  <TabBar*/}
      {/*    name="home"*/}
      {/*    type="home"*/}
      {/*    sideBar={true}*/}
      {/*    dataset={HOME_TAB_NAMES}*/}
      {/*    activeTab={activeTab.home}*/}
      {/*    variant="fullWidth"*/}
      {/*    onClick={() => {*/}
      {/*      navigate('/');*/}
      {/*      handleToggleMenu();*/}
      {/*    }}*/}
      {/*    sx={{*/}
      {/*      minHeight: 'auto',*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</ThemeProvider>*/}

      {/*<Divider color="#EBEBEB" thickness="thick" />*/}

      <Box p={2}>
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: '16px',
            font: 'normal normal bold 16px/24px Noto Sans CJK KR',
            color: '#191919',
          }}
        >
          전체 카테고리
        </Typography>
      </Box>
      <Divider color="#EBEBEB" thickness="thin" />
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
          <TabBar
            orientation="vertical"
            name="drawerCate"
            dataset={newCategories}
            activeTab={activeTab.drawerCate}
          />

          {newCategories?.map((item, idx) => (
            <TabPanel
              key={item.code}
              tabIndex={idx}
              name="drawerCate"
              value={activeTab.drawerCate + 1}
              sx={{ p: 1 }}
              xs={8}
            >
                {secondLevelCategoriesByParentCode?.map((category, tabIdx) => (
                    <Typography
                        key={category.code}

                        fontSize={category.code === activeMenu.secondDrawerCate ? "0.9rem" :"0.8rem"}
                        lineHeight="2.4rem"
                        fontWeight={category.code === activeMenu.secondDrawerCate ? "bold" :"normal"}
                        onClick={() => {
                            handleClickSecondCat(category.code, tabIdx);
                        }}
                    >
                        {category.name}
                    </Typography>
                ))}
            </TabPanel>
          ))}
            {secondLevelCategoriesByParentCode?.map((item, idx) => (
                <TabPanel
                    key={item.code}
                    tabIndex={idx}
                    name="secondDrawerCate"
                    value={activeTab.secondDrawerCate}
                    sx={{ p: 1 }}
                    xs={8}
                >
                    {thirdLevelCategoriesByParentCode?.map(category => (
                        <Typography

                            key={category.code}

                            lineHeight="2.4rem"
                            fontWeight={category.code === activeMenu.thirdDrawerCate ? "bold" :"normal"}
                            fontSize={category.code === activeMenu.thirdDrawerCate ? "0.9rem" :"0.8rem"}
                            onClick={() => {
                                handleToggleMenu();
                                handleJumpTab(category.code, idx);
                            }}
                        >
                            {category.name}
                        </Typography>
                    ))}
                </TabPanel>
            ))}

        </Box>
      </ThemeProvider>

      <Link to="/profile" style={{ textDecoration: 'none', color: 'inherit' }}>
        <LinkedBox
          name="마이페이지"
          onClick={() => {
            handleToggleMenu();
          }}
        />
      </Link>
      <Link to="/FAQ" style={{ textDecoration: 'none', color: 'inherit' }}>
        <LinkedBox
          name="FAQ"
          onClick={() => {
            handleToggleMenu();
          }}
        />
      </Link>

      <LinkedBox
        name="공지사항"
        onClick={() => {
          moveURLTo('https://adsward.co.kr/board/noticeList');
        }}
      />
      <LinkedBox
        name="광고적립내역"
        onClick={() => {
          moveURLTo('https://adsward.co.kr/mypage/saveHistoryList');
        }}
      />
     {/* <LinkedBox
        name="쇼핑적립내역"
        onClick={() => {
          handleToggleMenu();
          navigate('/pointhistory');
        }}
      />*/}

      {/* <LinkedBox
        name="랭킹보기"
        onClick={() => {
          moveURLTo('https://adsward.co.kr/mypage/adwardRanking');
        }}
      /> */}
      {/* <LinkedBox
        name="광고알림설정"
        onClick={() => {
          moveURLTo('https://adsward.co.kr/mypage/pushSetting');
        }}
      /> */}
      <LinkedBox
        name="로그아웃"
        onClick={() => {
          dispatch(signOut());
          moveURLTo('https://adsward.co.kr/logout');
        }}
      />

      <DrawerFooter />
    </Container>
  );
}

const DrawerFooter = () => {
  const [termsOpen, setTermsOpen] = useState(false);
  const [content, setContent] = useState('');

  return (
    <Box pl={2} pt={3} pb={5} sx={{ bgcolor: '#F8F8FA' }}>
      <Modal open={termsOpen} onClose={setTermsOpen} closeButton>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
          }}
        >
          <Header
            noBackButton
            title={content === '이용약관' ? '이용약관' : '개인정보보호정책'}
          />
        </Box>
        {content === '이용약관' ? terms[0].detail : terms[1].detail}
      </Modal>

      <Typography fontWeight="bold" fontSize="12px">
        이용에 불편한 점이나 제안, 요청사항이 있으신가요?
        <br />
        언제든 관리자에게 연락주시기 바랍니다.
        <br />
        info@adsward.co.kr
      </Typography>
      <Box mt={1}>
        <Typography
          variant="span"
          color="grey.600"
          fontWeight="600"
          fontSize="0.9rem"
          pr={1}
          sx={{ borderRight: '2px solid #eee' }}
          onClick={() => {
            setContent('이용약관');
            setTermsOpen(true);
          }}
        >
          이용약관
        </Typography>
        <Typography
          variant="span"
          color="grey.600"
          fontWeight="600"
          fontSize="0.9rem"
          pl={1}
          onClick={() => {
            setContent('개인정보보호정책');
            setTermsOpen(true);
          }}
        >
          개인정보보호정책
        </Typography>
      </Box>
    </Box>
  );
};

const LinkedBox = ({ name, ...rest }) => (
  // Todo: Think other component name
  <Box name={name} {...rest}>
    <Box p={1.8}>
      <Grid container justifyContent="space-between">
        <Grid item alignSelf="center">
          <Typography
            sx={{ font: 'normal normal bold 14px/20px Noto Sans CJK KR' }}
          >
            {name}
          </Typography>
        </Grid>

        <ArrowForwardIosIcon fontSize="medium" />
      </Grid>
    </Box>
    <Divider />
  </Box>
);

const theme = createTheme({
  components: {
    MuiTabs: {
      defaultProps: {
        TabIndicatorProps: {
          style: {
            display: 'none',
          },
        },
      },
      styleOverrides: {
        root: {
          borderRight: 1,
          borderColor: 'divider',
          backgroundColor: '#F8F8FA',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          alignItems: 'self-start',

          '&.Mui-selected': {
            color: '#191919',
            backgroundColor: '#FFFFFF',
          },
        },
      },
    },
  },
});
