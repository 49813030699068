import { Box, Grid, Typography } from '@mui/material';

import PageLayout from 'components/layout/PageLayout';
import Modal from 'components/Modal2';
import Divider from 'components/Divider';
import Barcode from 'react-barcode';
import parse from 'html-react-parser';
import React from "react";

export default function CouponDetailInfo({
  open,
  setOpen,
  info,
  openDelivery,
  setOpenDelivery,
}) {
    const copyValue = (item) => {
        var detail = item.content + "\n\n" + item.goodsExpl.replaceAll("</div>", "").replaceAll("<br/>", "\n").replaceAll("</br>", "\n").replaceAll("<div>", "")

        var details = detail.split("* 유효기간");

        detail = details[0] + "* 쿠폰인증번호 : " + item.pinPassCode + "\n* 유효기간" + details[1];
        var input = document.createElement('textarea');
        document.body.appendChild(input);
        input.value = detail;
        input.select();
        document.execCommand('copy', true,detail);
        document.body.removeChild(input);
        alert(`쿠폰 상세 복사되었습니다.`);

    };

  return (
    <Modal open={open} closeButton onClose={() => setOpen(false)}>
      {info && (
        <PageLayout title="쿠폰 상세정보" noBackButton={true}>
          <Divider thickness="13px" />
          <Box minHeight="calc(100vh - 208px)" px={3} pb={4}>
            <Box py={1.5}>
              <TableCustom>
                <TrCustom>
                  <TdCustomWithHtmlRender w="10%" text={"<span>" + info.couponItems[0].content.replaceAll("\n", "</br>") + "</span>"} />
                </TrCustom>
              </TableCustom>
            </Box>
            <Divider thickness="5px" />
            <Box py={1.5}>
              <Typography fontWeight="bold" py={1}>
                쿠폰상품
              </Typography>
              {info?.couponItems?.map(item => (
                <Grid container key={item.trId} spacing={1} mb={2}>
                  <Grid item xs={3}>
                    <img
                      src={item.goodsImg}
                      alt={item.goodsName}
                      width="100%"
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <TableCustom>
                        <TrCustom>
                            <TdCustomQrCode
                                text={`${item.pinNo}`}
                            />
                        </TrCustom>
                        <TrCustom>
                            <TdCustomWithCopy
                                w="100%"
                                onClick={()=>copyValue(item)}
                                text={`쿠폰번호 : ${item.pinNo}`}
                            />
                        </TrCustom>
                        <TrCustom>
                            <TdCustom
                                w="100%"

                                text={`쿠폰인증번호 : ${item.pinPassCode}`}
                            />
                        </TrCustom>
                        <TrCustom>
                            <TdCustom
                                w="100%"
                                text={`쿠폰상태 : ${item.pinStatusNm}`}
                            />
                        </TrCustom>
                        <TrCustom>
                            <TdCustom
                                w="100%"

                                text={`상품아이디 : ${item.goodsId}`}
                            />
                        </TrCustom>
                        <TrCustom>
                            <TdCustom
                                w="100%"
                                text={`상품명 : ${item.goodsName}`}
                            />
                        </TrCustom>
                        <TrCustom>
                            <TdCustom
                                w="100%"
                                text={`상품공급사명 : ${item.goodsComName}`}
                            />
                        </TrCustom>
                        <TrCustom>
                            <TdCustom
                                w="100%"
                                text={`브랜드명 : ${item.brandName}`}
                            />
                        </TrCustom>
                        {/*<TrCustom>*/}
                        {/*    <TdCustomWithHtmlRender*/}
                        {/*        w="100%"*/}
                        {/*        text={`${item.goodsExpl.replaceAll("</div>", "</p>").replaceAll("<div>", "<p>")}`}*/}
                        {/*    />*/}
                        {/*</TrCustom>*/}
                        <TrCustom>
                            <TdCustom
                                w="100%"
                                text={`유효기간 : ${item.limitDate}일`}
                            />
                        </TrCustom>
                        <TrCustom>
                            <TdCustom
                                w="100%"
                                text={`유효기간종료일 : ${item.limitEndDate}`}
                            />
                        </TrCustom>
                    </TableCustom>
                  </Grid>
                </Grid>
              ))}
            </Box>
            {/*<Divider thickness="5px" />*/}
            {/*<Box py={1.5}>*/}
            {/*  <Typography fontWeight="bold" py={1}>*/}
            {/*    결제정보*/}
            {/*  </Typography>*/}
            {/*  <TableCustom>*/}
            {/*    /!**/}
            {/*    <TrCustom>*/}
            {/*      <TdCustom w="30%" bg="#eee" text="총 상품수" />*/}
            {/*      <TdCustom w="70%" text={`${info.orderItems?.length}건`} />*/}
            {/*    </TrCustom>*/}

            {/*    <TrCustom>*/}
            {/*      <TdCustom w="30%" bg="#eee" text="총 상품금액" />*/}
            {/*      <TdCustom w="70%" text={`${}`} />*/}
            {/*    </TrCustom>*/}
            {/*    <TrCustom>*/}
            {/*      <TdCustom w="30%" bg="#eee" text="배송비" />*/}
            {/*      <TdCustom w="70%" text={`info.`} />*/}
            {/*    </TrCustom> *!/*/}
            {/*    <TrCustom>*/}
            {/*      <TdCustom w="30%" bg="#eee" text="사용포인트" />*/}
            {/*      <TdCustom w="70%" text={`${info.totalUsePoint}`} />*/}
            {/*    </TrCustom>*/}
            {/*    <TrCustom>*/}
            {/*      <TdCustom w="30%" bg="#eee" text="최종 결제금액" />*/}
            {/*      <TdCustom*/}
            {/*        w="70%"*/}
            {/*        text={parseStringToPrice(info.totalPayment)}*/}
            {/*      />*/}
            {/*    </TrCustom>*/}
            {/*    <TrCustom>*/}
            {/*      <TdCustom w="30%" bg="#eee" text="최종 결제수단" />*/}
            {/*      <TdCustom w="70%" text={info.paymentMethod} />*/}
            {/*    </TrCustom>*/}
            {/*  </TableCustom>*/}

            {/*  <Typography fontWeight="bold" py={1}>*/}
            {/*    수익금 적립내역*/}
            {/*  </Typography>*/}
            {/*  <TableCustom>*/}
            {/*    <TrCustom>*/}
            {/*      <TdCustom w="30%" bg="#eee" text="수익금 적립" />*/}
            {/*      <TdCustom*/}
            {/*        w="70%"*/}
            {/*        text={parseStringToPrice(info.totalGetPoint)}*/}
            {/*      />*/}
            {/*    </TrCustom>*/}
            {/*  </TableCustom>*/}
            {/*</Box>*/}
          </Box>
        </PageLayout>
      )}
    </Modal>
  );
}

export const TableCustom = ({ children }) => (
  <table
    style={{
      width: '100%',
      marginBottom: '12px',
      border: '1px solid #eee',
      borderCollapse: 'collapse',
    }}
  >
    <tbody>{children}</tbody>
  </table>
);
export const TrCustom = ({ children }) => (
  <tr style={{ border: '1px solid #eee' }}>{children}</tr>
);
export const TdCustom = ({ w, bg, text, ...rest }) => (
    <td
        style={{
            width: w,
            padding: '10px',
            backgroundColor: bg,
            textIndent: '6px',
            fontSize: '0.8rem',
        }}
        {...rest}
    >
        {text}
    </td>
);

export const TdCustomQrCode = ({ w, bg, text,  ...rest }) => (
    <td
        style={{
            width: w,
            padding: '10px',
            backgroundColor: bg,
            textIndent: '6px',
            fontSize: '0.8rem',
        }}
        {...rest}
    >
        <Barcode value={text} />
    </td>
);
export const TdCustomWithHtmlRender = ({ w, bg, text, ...rest }) => {
    const copyText = `${text}`;
    return (
        <td
            style={{
                width: w,
                padding: '10px',
                backgroundColor: bg,
                textIndent: '6px',
                fontSize: '0.8rem',
            }}
            {...rest}
        >
            {parse(copyText)}
        </td>
    );
}
export const TdCustomWithCopy = ({ w, bg, text, onClick, ...rest }) => {
    var texts = text.split(":");
    var copyText = texts[1];
    if(texts.length > 1)
    {
        copyText = `${text}`;
    }
    return ( <td
        style={{
            width: w,
            padding: '10px',
            backgroundColor: bg,
            textIndent: '6px',
            fontSize: '0.8rem',
        }}
        {...rest}
    >
        <div
            onClick={()=>onClick()}
        >
            {parse(copyText)}
        </div>
    </td>)
}
