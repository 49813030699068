import API from 'api';

export const fetchLinkPriceList = async () => {
  try {
    const response = await API.get('v1/linkPrice/merchants');

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
export const fetchTNKList = async page => {
  try {
    const response = await API.get(`v1/tnk/all?ip=49.254.175.22&page=${page}`);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchLinkPriceCategoryList = async () => {
  try {
    const response = await API.get(`v1/linkPrice/merchantCategoryList`);
    const otherCat = response.data.categories.filter(item=> item === "기타");
    const shoppingCenter = response.data.categories.filter(item=> item === "종합쇼핑몰");
    const newArr = ['전체', shoppingCenter[0], ...response.data.categories.filter(item=> item !== "기타" && item !== "종합쇼핑몰")];
    // if(otherCat.length > 0)
    // newArr.push(otherCat);

    return newArr;
  } catch (error) {
    console.error(error);
  }
};

export const fetchLinkPriceCategoryListItems = async categoryName => {
  try {
    const response = await API.get(
      `v1/linkPrice/merchantsByCategoryName/${categoryName}`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchLinkPriceItemsBySearch = async (keyword, category, page) => {
  try {
    const response = await API.post(`v1/linkPrice/merchantsSearch`, {
      searchWord: keyword,
      categoryName: category,
      page: page,
      size: 30,
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTNKCategoryList = async () => {
  try {
    const response = await API.get(`v1/tnk/categories`);
    const newArr = ['전체', ...response.data.categories];

    return newArr;
  } catch (error) {
    console.error(error);
  }
};

export const fetchTNKCategoryListItems = async (category, page) => {
  try {
    const response = await API.get(
      `v1/tnk/allByCategoryName?ip=49.254.175.22&categoryName=${category}&page=${page}`,
    );

    return response.data;
  } catch (error) {
    console.error(error);
  }
};
