import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import DaumPostcode from 'react-daum-postcode';
import { loadTossPayments } from '@tosspayments/payment-sdk';
import { v4 as uuidv4 } from 'uuid';

import {
  Grid,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Input,
  InputLabel,
  InputAdornment,
  Button,
  IconButton,
  RadioGroup,
  Radio,
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';

import Modal from 'components/Modal';

import PaymentSuccess from 'pages/payment/PaymentSuccess';
import {
  initOrderInfoId,
  makeMultipleOrder,
  makeOrder,
  setPayWithPoint,
} from 'store/order';

import { SHIPPING_REQUESTS } from 'contants';
import {
  ProvidePersonalInfoAgreementModal,
  CollectPersonalInfoAgreementModal,
  CancelAndRefundModal,
} from 'contants/terms';
import { parseStringToPrice } from 'utils';

import ItemDisplayForPayment from './ItemDisplayForPayment';
import { updateUserInfo } from 'api/user';

import PaymentFailure from 'pages/payment/PaymentFailure';
import { fetchPaymentByPoint } from 'api/order';

export default function Payment({ status }) {
  const orderState = useSelector(state => state?.order);
  const orderDetail = useSelector(state => state?.order.orderDetail);
  const orderInfoId = useSelector(state => state?.order.orderInfoId);
  const user = useSelector(state => state?.auth.user);

  const orderCartItems = useSelector(state => state?.cart.orderCartItems);
  const orderItemsInfo = useSelector(state => state?.cart.orderItemsInfo);

  const dispatch = useDispatch();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [tossOrderId, setTossOrderId] = useState(uuidv4());
  const [isOpenSearchAddressModal, setIsOpenSearchAddressModal] =
    useState(false);
  const [isOpenAgreementsModal, setIsOpenAgreementsModal] = useState({
    0: false,
    1: false,
    2: false,
  });
  const [isReady, setIsReady] = useState(false);
  const [agreementsBoxOpen, setAgreementsBoxOpen] = useState(false);
  const [pointValue, setPointValue] = useState(0);
  const [phoneValue, setPhoneValue] = useState('');
  const [textInputChange, setTextInputChange] = useState('');
  const [newBasicAddress, setNewBasicAddress] = useState(false);
  const [shippingDocument, setShippingDocument] = useState({
    isSameInfoWithUser: true, // not required
    destinationName: '', // not required
    recipient: user?.name,
    phoneNumber: user?.phone || '',
    address: {
      zipCode: user?.zipCode,
      road: user?.isStreetAddress ? user?.basicAddress : '',
      jibun: user?.isStreetAddress ? '' : user?.basicAddress,
      extraAddress: user?.detailAddress,
    },
    isRegisteredDefaultAddress: false, // not required
    shippingRequest: '',
    agreements: {
      0: false,
      1: false,
      2: false,
    },
    point: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'ready' && !orderDetail?.hasInfo) {
      alert('주문상품 정보가 없습니다. 다시 시도해주세요!');
      navigate('/');
    }
    setShippingDocument({
      ...shippingDocument,
      point: pointValue,
    });
  }, []);

  useEffect(() => {
    if (!orderInfoId) return;
    if (Number(pointValue) === orderDetail.amount + orderDetail.shippingFee) {
      navigate(`/payment/${orderInfoId}/success`);
      dispatch(setPayWithPoint(true));
      return;
    }
    makeTossPayment();
  }, [orderInfoId]);

  useEffect(() => {
    return () => dispatch(initOrderInfoId());
  }, []);

  useEffect(() => {
    if (!shippingDocument.shippingRequest) {
      setShippingDocument({
        ...shippingDocument,
        shippingRequest: '없음',
      });
    }

    if (checkDocumentBeWrittenAll(shippingDocument)) {
      setIsReady(true);

      return;
    }

    setIsReady(false);
  }, [shippingDocument]);

  const checkPhoneNumberForm = value => {
    const inspectIsNum = new RegExp(/^[0-9]*$/).test(value);
    const inspectStartNum = new RegExp(/^01/).test(value);

    if (inspectIsNum && inspectStartNum && value?.length === 11) {
      return true;
    } else {
      alert('휴대폰번호를 확인해주세요');
      return false;
    }
  };

  const updateUserAddress = async () => {
    if (!newBasicAddress) return;
    const data = {
      isStreetAddress: shippingDocument.address?.road ? true : false,
      basicAddress: shippingDocument.address?.road
        ? shippingDocument.address.road
        : shippingDocument.address.jibun,
      detailAddress: shippingDocument.address.extraAddress,
      zipCode: shippingDocument.address.zipCode,
    };
    await updateUserInfo(data);
  };

  const handlePointInput = e => {
    const { value } = e.target;
    if (value < 0) {
      alert('0보다 큰 수를 입력하세요.');
      return;
    }
    if (value > user?.point) {
      alert('보유 수익금보다 작은 수를 입력해주세요.');
      return;
    }
    if (value > orderDetail.amount + orderDetail.shippingFee) {
      alert('사용 포인트는 결제 금액을 초과할 수 없습니다.');
      return;
    }
    if (!value) {
      setPointValue(0);
      setShippingDocument({
        ...shippingDocument,
        [e.currentTarget.name]: 0,
      });

      return;
    }

    setPointValue(value);
  };

  const handleTextInputChange = e => {
    const { value } = e.target;
    setTextInputChange(value);
  };
  const handleChangeInput = e => {
    if (e.currentTarget.id === 'isSameInfoWithUser') {
      setShippingDocument({
        ...shippingDocument,
        isSameInfoWithUser: !shippingDocument.isSameInfoWithUser,
        recipient: user?.name,
        phoneNumber: user?.phone,
        address: {
          zipCode: user?.zipCode,
          road: user?.isStreetAddress ? user?.basicAddress : '',
          jibun: user?.isStreetAddress ? '' : user?.basicAddress,
          extraAddress: user?.detailAddress,
        },
      });
      return;
    }

    if (e.currentTarget.id === 'phoneNumber') {
      //제출하기 전에 number인지, 글자 길이 체크, 01~로 시작하는지 체크
      // let phoneNumberForm = '';
      const value = e.currentTarget.value;
      const inspectPhoneNumber = str => new RegExp(/^[0-9]*$/).test(str);

      if (value?.length === 11) {
        if (!inspectPhoneNumber(value)) {
          alert('숫자만 입력해주세요');
          return;
        }

        // phoneNumberForm = e.currentTarget.value.replace(
        //   /(\d{3})(\d{4})(\d{4})/,
        //   '$1-$2-$3',
        // );

        setShippingDocument({
          ...shippingDocument,
          phoneNumber: value,
        });
        setPhoneValue(value);

        return;
      }
      if (value?.length > 11) {
        e.currentTarget.value = phoneValue;
      }
    }

    setShippingDocument({
      ...shippingDocument,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handlePayment = async () => {

    if (pointValue < (orderDetail.amount + orderDetail.shippingFee) && pointValue > (orderDetail.amount + orderDetail.shippingFee) / 4  ) {
      alert('포인트 차감으로 1 포인트부터 결제금액의 25%까지 사용가능합니다.');
      return;
    }

    const essentialOrderData = {
      recipientName: shippingDocument.recipient,
      recipientPhoneNumber: shippingDocument.phoneNumber,
      zipCode: shippingDocument.address.zipCode,
      jibunAddress: shippingDocument.address.jibun,
      address: shippingDocument.address.road,
      detailAddress: shippingDocument.address.extraAddress,
      shippingRequest: shippingDocument.shippingRequest,
      ordererName: user.name,
      tossOrderId: tossOrderId,
      amount: orderDetail.amount + orderDetail.shippingFee,
      usePoint: pointValue,
      textOption: orderState.orderDetail.textOption,
      userAgreement1: shippingDocument.agreements[0],
      userAgreement2: shippingDocument.agreements[1],
      userAgreement3: shippingDocument.agreements[2],
    };

    const orderData = {
      ...essentialOrderData,
      itemId: orderDetail.itemId,
      itemCount: orderDetail.itemCount,
      optionToOrderItems: orderDetail.optionToOrderItems,
    };
    const cartOrderData = {
      ...essentialOrderData,
      orderItems: orderItemsInfo,
    };

    const willPayAmount = orderDetail.amount + orderDetail.shippingFee;

    if (Number(pointValue) === willPayAmount) {
      const confirmed = window.confirm('포인트로만 결제됩니다');
      if (!confirmed) {
        return;
      }
    }
    if (orderCartItems && orderItemsInfo?.length !== 1)
      dispatch(makeMultipleOrder(cartOrderData));
    if (!orderCartItems || orderItemsInfo?.length === 1)
      dispatch(makeOrder(orderData));
  };

  const tossKey = 'live_ck_0Poxy1XQL8RnG5XGR0l87nO5Wmlg';
  const makeTossPayment = async () => {
    const tossPayments = await loadTossPayments(tossKey);

    tossPayments.requestPayment('카드', {
      amount: orderDetail.amount + orderDetail.shippingFee - pointValue,
      orderId: tossOrderId,
      orderName: orderDetail.itemName,
      customerName: user.name,

      successUrl: `http://adsmall.co.kr/payment/${orderInfoId}/success`,
      failUrl: 'http://adsmall.co.kr/payment/fail',

      //successUrl: `http://adsmall.co.kr/payment/${orderInfoId}/success`,
      //failUrl: 'http://adsmall.co.kr/payment/fail',
    });
  };

  if (status === 'success') {
    return <PaymentSuccess shippingDocument={shippingDocument} />;
  }

  if (status === 'fail') {
    return <PaymentFailure />;
  }

  return (
    <>
      <Box
        pt={1.5}
        pb={1.5}
        sx={{
          position: 'sticky',
          top: 0,
          bgcolor: '#ffffff',
          borderBottom: '1px solid #e5e5e5',
          zIndex: 10,
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography fontSize="18px" fontWeight={600} align="center">
          주문결제
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: '#ffffff',
          border: '1px solid #e5e5e5',
          borderRadius: 2,
        }}
        m={2}
        p={2}
      >
        <Typography fontSize="16px" fontWeight={700} mb={3}>
          배송지
        </Typography>
        <Box mb={2}>
          <InputLabel htmlFor="recipient">수령자명</InputLabel>
          <Input
            id="recipient"
            name="recipient"
            type="text"
            fullWidth
            placeholder="수령자명 입력"
            value={shippingDocument.recipient}
            onChange={handleChangeInput}
          />
        </Box>
        <Box mb={2}>
          <InputLabel htmlFor="phoneNumber">휴대폰</InputLabel>
          <Input
            id="phoneNumber"
            name="phoneNumber"
            fullWidth
            placeholder="'-'없이 숫자 입력"
            value={shippingDocument.phoneNumber}
            onChange={handleChangeInput}
          />
        </Box>
        <Box mb={2}>
          <InputLabel htmlFor="address">주소</InputLabel>
          <Input
            id="address"
            fullWidth
            value={shippingDocument.address.zipCode}
            endAdornment={
              <>
                <Box mb={1}>
                  <InputAdornment position="end">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setIsOpenSearchAddressModal(true);
                      }}
                      size="small"
                    >
                      주소검색
                    </Button>
                  </InputAdornment>
                </Box>
              </>
            }
          />
        </Box>
        <Box mb={2}>
          <Input
            fullWidth
            placeholder="주소"
            value={shippingDocument.address.road}
          />
        </Box>
        <Box mb={2}>
          <Input
            fullWidth
            placeholder="나머지 주소 입력"
            value={shippingDocument.address.extraAddress}
            onChange={e =>
              setShippingDocument({
                ...shippingDocument,
                address: {
                  ...shippingDocument.address,
                  extraAddress: e.target.value,
                },
              })
            }
          />
        </Box>
        <Box mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckCircleOutlineIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            name="isRegisteredDefaultAddress"
            onChange={e => {
              setShippingDocument({
                ...shippingDocument,
                isRegisteredDefaultAddress:
                  !shippingDocument.isRegisteredDefaultAddress,
              });
              if (e.target.checked) setNewBasicAddress(true);
              if (!e.target.checked) setNewBasicAddress(false);
            }}
            label="기본 배송지로 등록"
            labelPlacement="end"
          />
        </Box>

        <Typography mb={1}>배송 요청사항을 선택해주세요.</Typography>
        <Button
          variant="outlined"
          fullWidth
          endIcon={<ArrowDropDownIcon />}
          onClick={() => setIsOpenModal(true)}
        >
          <Typography sx={{ width: 1 }} align="left">
            {shippingDocument?.shippingRequest}
          </Typography>
        </Button>
        {/* 직접 입력을 선택한 경우 */}
        {shippingDocument.shippingRequest === '직접입력' && (
          <Box mt={2}>
            <Input
              name="shippingRequest"
              fullWidth
              placeholder="배송시 요청사항을 입력해 주세요."
              value={textInputChange}
              onChange={handleTextInputChange}
              onBlur={e => {
                if (textInputChange === '') {
                  alert('요청사항을 작성해주세요');
                  return;
                }
                handleChangeInput(e);
              }}
            />
          </Box>
        )}
        <ShippingRequestsModal
          open={isOpenModal}
          value={shippingDocument.shippingRequest}
          onClose={() => setIsOpenModal(false)}
          onChange={handleChangeInput}
        />
        <SearchAddressModal
          open={isOpenSearchAddressModal}
          onClose={() => setIsOpenSearchAddressModal(false)}
          shippingDocument={shippingDocument}
          setShippingDocument={setShippingDocument}
        />
        <ProvidePersonalInfoAgreementModal
          open={isOpenAgreementsModal[0]}
          onClose={() =>
            setIsOpenAgreementsModal({
              ...isOpenAgreementsModal,
              0: !isOpenAgreementsModal[0],
            })
          }
        />
        <CollectPersonalInfoAgreementModal
          open={isOpenAgreementsModal[1]}
          onClose={() =>
            setIsOpenAgreementsModal({
              ...isOpenAgreementsModal,
              1: !isOpenAgreementsModal[1],
            })
          }
        />
        <CancelAndRefundModal
          open={isOpenAgreementsModal[2]}
          onClose={() =>
            setIsOpenAgreementsModal({
              ...isOpenAgreementsModal,
              2: !isOpenAgreementsModal[2],
            })
          }
        />
      </Box>

      <ItemDisplayForPayment />
      <Box
        sx={{
          bgcolor: '#ffffff',
          border: '1px solid #e5e5e5',
          borderRadius: 2,
        }}
        m={2}
        p={2}
      >
        <Typography fontSize="16px" fontWeight={700} mb={2}>
          결제금액
        </Typography>

        <Grid container justifyContent="space-between" mb={0.5}>
          <Typography fontSize="14px" fontWeight={400} color="#999">
            상품금액
          </Typography>
          <Typography fontSize="14px" fontWeight={700} color="#1a1a1a">
            {parseStringToPrice(orderDetail.amount - orderDetail.additionalFee)}
            원
          </Typography>
        </Grid>

        <Grid container justifyContent="space-between" mb={0.5}>
          <Typography fontSize="14px" fontWeight={400} color="#999">
            추가옵션
          </Typography>
          <Typography fontSize="14px" fontWeight={700} color="#1a1a1a">
            {parseStringToPrice(orderDetail.additionalFee)}원
          </Typography>
        </Grid>

        <Grid container justifyContent="space-between" mb={0.5}>
          <Typography fontSize="14px" fontWeight={400} color="#999">
            포인트 차감 금액
          </Typography>
          <Box
            sx={{
              position: 'relative',
              height: '50px',
              mb: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'end',
            }}
          >
            <Box>
              <Input
                id="recipient"
                name="point"
                type="number"
                placeholder="0"
                sx={{
                  position: 'relative',
                  width: '80px',
                  height: '34px',
                  marginRight: '8px',
                  marginBottom: '7px',
                  border: '1px solid',
                  borderColor: 'common.black',
                  borderRadius: '5px',
                  ':hover': {
                    outline: '0px solid',
                  },
                  input: {
                    height: '34px',
                    textAlign: 'right',
                    paddingLeft: '3px',
                    paddingRight: '3px',
                    paddingTop: '8px !important',
                    paddingBottom: '8px !important',
                    // WebkitAppearance: 'none',
                    ':hover': {
                      height: '10px',
                      borderBottom: '0px solid',
                    },
                    // ':focus': {
                    //   paddingTop: '4px !important',
                    //   paddingBottom: '4px !important',
                    // },
                  },
                  '& .MuiInput-root:after': {
                    border: '1px solid red',
                  },
                  ':after': {
                    height: '100%',
                    border: '2px solid red',
                    borderBottom: '0',
                    borderRadius: '5px',
                  },
                  '::before': {
                    borderBottom: '0px solid',
                  },
                }}
                value={pointValue}
                onChange={e => {
                  handleChangeInput(e);
                  handlePointInput(e);
                }}
                onFocus={() => setPointValue('')}
                onBlur={e => {
                  handleChangeInput(e);
                  handlePointInput(e);
                }}
              />
              <span>P</span>
            </Box>
            <Typography sx={{ fontSize: '0.9rem' }}>
              보유 수익금 :
              <span style={{ color: 'red' }}>
                {' '}
                {parseStringToPrice(user?.point - pointValue)}P
              </span>
            </Typography>
          </Box>
        </Grid>

        <Grid container justifyContent="space-between" mb={0.5}>
          <Typography fontSize="14px" fontWeight={400} color="#999">
            배송비
          </Typography>
          <Typography fontSize="14px" fontWeight={700} color="#1a1a1a">
            {parseStringToPrice(orderDetail.shippingFee)}원
          </Typography>
        </Grid>

        <Grid container justifyContent="space-between" mt={2}>
          <Typography fontSize="18px" fontWeight={700}>
            총 결제금액
          </Typography>
          <Typography fontSize="18px" fontWeight={700} color="red">
            {parseStringToPrice(
              orderDetail.amount +
                orderDetail.shippingFee -
                shippingDocument.point,
            )}
            원
          </Typography>
        </Grid>
      </Box>

      <Box
        sx={{
          bgcolor: '#ffffff',
          border: '1px solid #e5e5e5',
          borderRadius: 2,
        }}
        m={2}
        p={2}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckCircleOutlineIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
              checked={
                shippingDocument.agreements[0] &&
                shippingDocument.agreements[1] &&
                shippingDocument.agreements[2]
              }
              onChange={e => {
                if (e.target.checked) {
                  setShippingDocument({
                    ...shippingDocument,
                    agreements: {
                      0: true,
                      1: true,
                      2: true,
                    },
                  });
                } else {
                  setShippingDocument({
                    ...shippingDocument,
                    agreements: {
                      0: false,
                      1: false,
                      2: false,
                    },
                  });
                }
              }}
              label="약관 전체 동의하기"
              labelPlacement="end"
            />
          </Grid>
          <Grid item>
            <IconButton onClick={() => setAgreementsBoxOpen(prev => !prev)}>
              {agreementsBoxOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>

        {agreementsBoxOpen && (
          <>
            <Typography fontSize="13px" color="#777">
              개인정보 제 3자 제공 동의, 상품 구매조건 확인 및 취소, 환불 규정
              동의 등 모든 약관에 동의합니다.
            </Typography>
            <Typography fontSize="13px" color="#777">
              (제품구매시 개인정보를 해당 제3자(판매자와 배송업체)에 제공하고
              있습니다. 구매진행하시면 동의한 것으로 간주합니다.)
            </Typography>
            <Typography fontSize="13px" color="#ff0000">
              만 14세 미만은 구매 불가합니다.
            </Typography>
            <Box sx={{ bgcolor: '#fafafa' }} p={2} mt={1}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckCircleOutlineIcon />}
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    checked={shippingDocument.agreements[0]}
                    onChange={e =>
                      setShippingDocument({
                        ...shippingDocument,
                        agreements: {
                          ...shippingDocument.agreements,
                          0: e.target.checked,
                        },
                      })
                    }
                    label={
                      <Box>
                        개인정보 제3자 제공 동의
                        <Typography component="span" color="#aaaaaa">
                          (필수)
                        </Typography>
                      </Box>
                    }
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() =>
                      setIsOpenAgreementsModal({
                        ...isOpenAgreementsModal,
                        0: !isOpenAgreementsModal[0],
                      })
                    }
                  >
                    보기
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckCircleOutlineIcon />}
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    checked={shippingDocument.agreements[1]}
                    onChange={e =>
                      setShippingDocument({
                        ...shippingDocument,
                        agreements: {
                          ...shippingDocument.agreements,
                          1: e.target.checked,
                        },
                      })
                    }
                    label={
                      <Box>
                        개인정보 수집 및 이용 동의
                        <Typography component="span" color="#aaaaaa">
                          (필수)
                        </Typography>
                      </Box>
                    }
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() =>
                      setIsOpenAgreementsModal({
                        ...isOpenAgreementsModal,
                        1: !isOpenAgreementsModal[1],
                      })
                    }
                  >
                    보기
                  </Button>
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<CheckCircleOutlineIcon />}
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    checked={shippingDocument.agreements[2]}
                    onChange={e =>
                      setShippingDocument({
                        ...shippingDocument,
                        agreements: {
                          ...shippingDocument.agreements,
                          2: e.target.checked,
                        },
                      })
                    }
                    label={
                      <Box>
                        상품 구매조건 확인/취소·환불 규정 동의
                        <Typography component="span" color="#aaaaaa">
                          (필수)
                        </Typography>
                      </Box>
                    }
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    onClick={() =>
                      setIsOpenAgreementsModal({
                        ...isOpenAgreementsModal,
                        2: !isOpenAgreementsModal[2],
                      })
                    }
                  >
                    보기
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>

      <Box
        p={2}
        sx={{
          position: 'sticky',
          bottom: 70,
          zIndex: 10,
          bgcolor: '#ffffff',
          borderRadius: '20px 20px 0 0',
          borderTop: '1px solid #e5e5e5',
        }}
      >
        <Button
          variant="contained"
          fullWidth
          disabled={isReady ? false : true}
          onClick={() => {
            if (!checkPhoneNumberForm(shippingDocument.phoneNumber)) return;
            handlePayment();
            updateUserAddress();
          }}
        >
          <Typography fontSize="18px" fontWeight={600}>
            {parseStringToPrice(
              orderDetail.amount + orderDetail.shippingFee - pointValue,
            )}
            원 결제하기
          </Typography>
        </Button>
      </Box>
    </>
  );
}

const SearchAddressModal = ({
  open,
  onClose,
  shippingDocument,
  setShippingDocument,
}) => {
  const onComplete = data => {
    let roadAddress = data.address;
    let extra = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extra += data.bname;
      }

      if (data.buildingName !== '') {
        extra += extra !== '' ? `, ${data.buildingName}` : data.buildingName;
      }

      roadAddress += extra !== '' ? ` (${extra})` : '';
    }

    setShippingDocument({
      ...shippingDocument,
      address: {
        ...shippingDocument.address,
        zipCode: data.zonecode,
        road: roadAddress,
        // daum API에서 지번 주소가 적혀있지 않은 경우가 있음
        jibun: data.jibunAddress ? data.jibunAddress : roadAddress,
      },
    });

    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} fullScreen>
      <IconButton sx={{ position: 'absolute', right: 0 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Typography m={2} align="center" fontSize="18px" fontWeight={400}>
        우편번호 검색
      </Typography>
      <DaumPostcode
        style={{ height: '100%' }}
        autoClose
        onComplete={onComplete}
      />
    </Modal>
  );
};

const ShippingRequestsModal = ({ open, onClose, value, onChange }) => {
  return (
    <Modal open={open} onClose={onClose} fullWidth maxWidth="xs">
      <IconButton sx={{ position: 'absolute', right: 0 }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Box m={2.5}>
        <Typography fontSize="17px" fontWeight={700} align="center" m={1}>
          배송 요청 사항
        </Typography>

        <RadioGroup name="shippingRequest" value={value} onChange={onChange}>
          {SHIPPING_REQUESTS.map(shippingRequest => (
            <FormControlLabel
              key={shippingRequest.id}
              control={<Radio />}
              value={shippingRequest.text}
              label={shippingRequest.text}
              onClick={onClose}
            />
          ))}
        </RadioGroup>
      </Box>
    </Modal>
  );
};

const checkDocumentBeWrittenAll = document => {
  const values = Object.values(document);
  const keys = Object.keys(document);
  let arr = [];

  for (let i = 0; i < values.length; i++) {
    if (
      keys[i] === 'isSameInfoWithUser' ||
      keys[i] === 'destinationName' ||
      keys[i] === 'isRegisteredDefaultAddress'
    ) {
      continue;
    }

    if (values[i] instanceof Object) {
      const keys1 = Object.keys(values[i]);
      const values1 = Object.values(values[i]);

      for (let j = 0; j < values1.length; j++) {
        if (document.address.road && keys1[j] === 'jibun') {
          continue;
        }
        arr.push(values1[j]);
      }
    } else {
      arr.push(values[i]);
    }
  }

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === '' || arr[i] === false) {
      return false;
    }
  }

  return true;
};
